<template>
  <div>
    <div class="header-terms w-100 py-10 text-white relative">
      <div v-if="from != 'profile'" class="signup flex justify-end px-20">
        <router-link :to="`/${$i18n.locale}/signup`" class="signup-terms"
          >Don't have an account? Sign up</router-link
        >
      </div>
      <div class="title-terms">
        Terms and conditions
      </div>
    </div>
    <div class="flex detail-terms">
      <div class="sidebar w-1/3">
        <ul>
          <li
            v-for="(sidebarItem, index) in sidebarItems"
            :key="index"
            class="sidebar-item"
            @click="currentItem = index"
          >
            <button
              :class="[currentItem === index ? 'sidebar-active' : '']"
              class="borderless text-left sidebar-btn"
            >
              {{ sidebarItem }}
            </button>
          </li>
        </ul>
      </div>
      <div class="sidebar-details w-2/3 pr-10 pb-20">
        <div v-if="currentItem == 0">
          <div class="title">
            What Type of User am I and Which Privacy Terms Are Applicable to Me?
          </div>
          <p dir="ltr">
            Workkami has three different types of users depending on the
            Workkami products used. Please see the bullets below to determine
            which type of user you are, and then click the internal link to
            visit the privacy terms applicable to you. It is possible that you
            may use Workkami in different ways. If so, please review all privacy
            terms. Also, don’t forget to review Section V, which contains
            privacy terms applicable to all users.
          </p>
          <p dir="ltr">
            Subscribers. We call users who use the Service as part of any tier
            of a paid Workkami subscription plan “Subscribers.” The Service
            features and functionalities available to Subscribers are determined
            by the specific terms agreed to between Workkami and the
            organization (e.g., your employer or another entity or person,
            called the “Customer”) that entered into a separate agreement that
            governs delivery, access, and use of the Service (for purpose of
            this Policy, the “Customer Agreement”). The Customer controls its
            instance of the Service and is the data controller of the
            information collected through the Service about Subscribers, and
            Workkami is a data processor of such information.
          </p>

          <p dir="ltr">
            Free Users. We call users who use a non-paid version of the Service
            “Free Users.” While Free Users can access and use the Service, they
            have access to a more limited set of Service features and
            functionality than Subscribers. Workkami is the data controller of
            the information collected through the Service about Free Users.
          </p>
          <p dir="ltr">
            Site Visitors. We call users of the Websites “Site Visitors.” Site
            Visitors can be individuals who are simply browsing the Websites but
            who do not use the Workkami Service; or, Site Visitors can be Free
            Users or Subscribers who visit the Websites to seek additional
            information about Workkami. Workkami is the data controller of the
            information collected through the Website about Site Visitors.
          </p>
        </div>
        <div v-else-if="currentItem == 1">
          <div class="title">Privacy Terms for Subscribers</div>
          <div class="subtitle">A. Overview</div>
          <p>
            Section II of this Policy applies only to Subscribers. If you are a
            Subscriber, the “Customer Agreement” governs the collection and
            processing of information collected from you through the Customer’s
            instance of the Service (e.g. a Customer’s organization or
            workspace, but for purposes of this Policy referred to as the
            “Workspace”), including all associated messages, attachments, files,
            tasks, projects and project names, team names, channels,
            conversations, and other content submitted through the Service
            (“Workspace Content”). In the event of a conflict between this
            Privacy Policy and the Customer Agreement, the Customer Agreement
            governs. Because the Customer controls the Workspace used by
            Subscribers, if you have any questions about the Customer’s specific
            Workspace settings and privacy practices, please contact the
            Customer whose Workspace you use. If you are a Subscriber located in
            the European Union, please note that the Customer is the data
            controller with respect to the processing of your Workspace Content
            pursuant to the EU General Data Protection Regulation (“GDPR”). When
            processing Workspace Content of EU data subjects governed by the
            Customer Agreement, Workkami is the data processor, meaning that we
            collect and process such information solely on behalf of the
            Customer.
          </p>
          <div class="subtitle">
            B. Collection and Use of Subscriber Information
          </div>
          <p>
            This section explains the information we collect from Subscribers.
            We do not require Subscribers to provide us with information.
            However, certain information, such as account log-in data, is
            required to provide you with access to the Service, and other
            information may be collected automatically as you use the Service.
            Workspace Content. Workspace Content is collected, used, and shared
            by Workkami in accordance with the Customer’s instructions,
            including any applicable terms in the Customer Agreement, or as
            required by applicable law. The Customer, and not Workkami,
            determines its own, internal policies regarding storage, access,
            modification, deletion, sharing, and retention of Workspace Content
            which may apply to your use of the Service. For example, a Customer
            may provide or remove access to the Service, enable or disable third
            party integrations, manage permissions, retention and export
            settings, transfer or assign teams, or share projects. Please check
            with the Customer about the policies and settings that they have
            instituted with respect the Workspace Content that you provide when
            using the Service. Account Information. To set up your Workkami
            account, you or the Customer will provide us with basic information
            about you which may include your name, address, telephone number,
            email address, and password. You will then have the ability to
            provide optional profile information, such as a photograph or basic
            demographic data. With your permission, we may also upload calendar
            information stored on your mobile device to your account. If you
            submit payment information in connection with your use of the
            Service, we utilize a third party credit card payment processing
            company to collect payment information, including your credit card
            number, billing address, and phone number. In such circumstances,
            the third party service provider, and not Workkami, stores your
            payment information on our behalf. Service Usage Information. As you
            use the Service, we collect information about how you use and
            interact with the Service (“Service Usage Information”). Such
            information includes: Device information – when you access the
            Service using a mobile device, we collect certain device
            information, including the type of device you are using, its
            operating system, and mobile network information, which may include
            your mobile phone number. We may also collect your MAC address and
            other unique device identifiers. Log files – when you use the
            Service, our servers automatically record information in server log
            files. These log files may include information such as your web
            request, IP address, browser type and settings, referring/exit pages
            and URLs, number of clicks, date and time stamp information,
            language preferences, data from cookies and similar technologies,
            and other such information. Location information – we collect and
            process general information about the location of the device from
            which you are accessing the Service (e.g., approximate geographic
            location inferred from an IP address). Workspace Use Metadata – when
            you interact with the Service, metadata is generated that provides
            high-level (non-content) information about the way you work in your
            Workspace. For example, we may log the number of Workspaces you work
            in; the number of tasks to which you are assigned; the features and
            embedded Service content you interact with; the types of files you
            share; and what, if any, third party services and integrations you
            use. Other Information. You may provide us with information when you
            interact with us in other ways, such as when you submit requests or
            questions to us via forms or email (e.g., support forms, sales
            forms, user research participation forms); information you provide
            in connection with Workkami sweepstakes, contests, or research
            studies in which you choose to participate; beta testing; and
            requests for customer support and technical assistance
            (collectively, “Other Information”). Information Collected from
            Third-Party Integrations. If you choose to use or connect to
            third-party integrations (e.g., OneDrive, Unito, Wufoo, Slack)
            through the Service, or if you are required or permitted to do so by
            a Customer, such third parties may allow us to have access to and
            store additional information about your interaction with those
            services as it relates to your use of the Service. If you initiate
            these connections, you also understand that we will share
            information about you that is required to enable your use of the
            third-party integration through the Service. If you do not wish to
            have this information shared, do not initiate these connections. By
            enabling these connections, you authorize us to connect and access
            the information provided through these connections, and you
            understand that the privacy policies of these third parties govern
            such connections.
          </p>
          <div class="subtitle">
            C. How Does Workkami Use Subscriber Information?
          </div>
          <p>
            This section explains how Workkami uses information collected from
            Subscribers. Workspace Content. Workkami may view and use Workspace
            Content collected from and about Subscribers only as necessary: To
            maintain, provide and improve the Service To prevent or address
            technical or security issues and resolve support requests To
            investigate when we have a good faith belief, or have received a
            complaint alleging, that such Workspace Content is in violation of
            the Customer Agreement or our User Terms of Service To comply with a
            valid legal subpoena, request, or other lawful process that meets
            the requirements of the Customer Agreement and our Law Enforcement
            Guidelines.
          </p>
          <p>
            As otherwise set forth in our Customer Agreement or as expressly
            permitted in writing by the Customer Account Information, Service
            Usage Information, Information from Third Party Integrations, and
            Other Information. Workkami may use these categories of information
            collected from and about Subscribers to: Maintain, provide, and
            improve the Service Respond to your requests for information Prevent
            or address technical or security issues and resolve support requests
            Investigate in good faith alleged violations of our User Terms of
            Service Comply with a valid legal subpoena, request, or other lawful
            process that meets the requirements of our Law Enforcement
            Guidelines Help us better understand user interests and needs, and
            customize the Service for our users Engage in analysis, research,
            and reports regarding use of the Service Protect the Service and our
            users Communicate with you via email and through the Service about
            important notices and updates regarding the Service, such as to
            inform you about changes in the Service, our service offerings, and
            important services-related notices, such as about security and
            fraud. Because these communications are an important part of the
            Service, you may not opt out of them.
          </p>
          <p>
            In accordance with applicable legal obligations, communicate with
            you about promotions, offers, and news about Workkami. You have the
            ability to unsubscribe from such promotional communications Provide
            cross-device management of your account. For example, we may locate
            or try to locate the same unique users across multiple browsers or
            devices (such as smartphones or tablets), or work with service
            providers that do this, in order to save your preferences across
            devices and analyze usage of the Service. If you wish to opt out of
            the ability of one our service providers, Google Analytics, to
            locate you across devices in this way, you may install the Google
            Analytics Opt-out Browser Add-on by clicking here.
          </p>
          <div class="subtitle">D. Sharing of Subscriber Information</div>
          <p>
            In accordance with the applicable Customer Agreement, we may share
            the information we collect from Subscribers as follows: Affiliates
            and Subsidiaries. We may share the information we collect within the
            Workkami family of companies. Service Providers. We may provide
            access to or share your information with select third parties that
            use the information only to perform services on our behalf. These
            third parties provide a variety of services to us, including without
            limitation sales, marketing, provision of content and features,
            analytics, data storage, security, fraud prevention, and other
            services. Business Transactions. If the ownership of all or
            substantially all of our business changes, we may transfer your
            information to the new owner so that the Service can continue to
            operate. In such case, your information would remain subject to the
            promises and commitments contained in this Policy until such time as
            the acquiring party modifies it. If such transfer is subject to
            additional mandatory restrictions under applicable laws, Workkami
            will comply with such restrictions. Consistent with your settings
            within the Service. Please note that the Workspace Content you
            submit through the Service may be viewable by other users in your
            Workspace and within your organization, depending on the specific
            settings you and your organization have selected.
          </p>
          <div class="subtitle">E. Aggregate De-Identified Data</div>
          <p>
            We may aggregate and/or de-identify information collected through
            the Service so that such information can no longer be linked to you
            or your device (“Aggregate/De-Identified Information”). We may use
            Aggregate/De-Identified Information for any purpose, including
            without limitation for research and analytics, and may also share
            such data with any third parties, including partners, affiliates,
            services providers, and others.
          </p>
          <div class="subtitle">F. Combined Information</div>
          <p>
            We may combine the information that we collect through the Service
            with information that we receive from other sources, both online and
            offline, and use such combined information in accordance with this
            Policy and the Customer Agreement.
          </p>
          <div class="subtitle">G. Data Retention</div>
          <p>
            We will retain your information for the period necessary to fulfill
            the purposes outlined in this Policy unless a longer retention
            period is required or permitted by law, or where the Customer
            Agreement requires or permits specific retention or deletion
            periods.
          </p>
          <div class="subtitle">H. Data Subject Rights</div>
          <p>
            Please contact your Workspace owner(s) or administrator(s) to
            exercise any data subject rights you have under applicable local
            laws, including your ability to access, delete, rectify, transfer,
            or object under the GDPR.
          </p>
          <div class="subtitle">I. Canceling & Ending User Subscriptions.</div>
          <p>
            Subscription fees are based on annual or monthly periods (or pro
            rata portions of such periods, calculated on a daily basis) that
            begin on the Subscription start date and each annual or monthly
            anniversary of the start date. Subscriptions to the Service are sold
            in Subscription Plans based on the number of Seats. Customer may add
            or remove Seats to their Subscription at any time. However, refunds
            will not be granted if the Customer cancels their Subscription Plan.
            Workkami reserves the right to calculate the total number of Seats
            on a periodic basis, and, if such number exceeds Customer’s current
            Subscription Plan size, Workkami reserves the right to invoice
            Customer for the Subscription Plan that corresponds to the number of
            Seats on a pro rata basis for the remaining months in Customer’s
            then-current annual Subscription Term, so that all Customer
            Subscription Terms are coterminous. Workkami reserves the right to
            revise fee rates and/or the billable amount structure for the
            Service at any time and will provide Customer with notice prior to
            the change. Workkami may charge Customer the then-current pricing
            for the applicable Subscription if the number of Seats is modified
            and/or if Customer changes its Subscription plan.
          </p>
        </div>
        <div v-else-if="currentItem == 2">
          <div class="title">Privacy Terms for Free Users</div>
          <div class="subtitle">A. Overview</div>
          <p dir="ltr">
            Section III of this Policy applies only to Free Users of the
            Service. If you are a Free User located in the European Union,
            Workkami is the data controller with respect to the processing of
            your personal data pursuant to the GDPR.
          </p>
          <div class="subtitle">
            B. Collection and Use of Free User Information
          </div>
          <p dir="ltr">
            his section explains how we collect, process, and use the
            information collected from Free Users. We do not require Free Users
            to provide us with information. However, certain information, such
            as account log-in data, is required to provide you with access to
            the Service, and other information may be collected automatically as
            discussed below. Information You Provide to Workkami. Workkami
            collects the following information submitted directly through the
            Service by Free Users: The messages, attachments, files, tasks,
            project names, team names, channels, conversations, and other
            content submitted through the Service (collectively, the “Workspace
            Content”); Information you provide as part of your account
            registration with Workkami, which may include your name,
            organization name, address, telephone number, email address,
            username and password; optional information that you may choose to
            provide, such as a photograph or basic demographic data; and, with
            your permission, calendar information stored on your mobile device
            that you may elect to upload to your account (collectively, the
            “Account Information”); and Information you provide in other
            interactions with us, such as requests or questions you submit to us
            via forms or email (e.g., support forms, sales forms, user research
            participation forms), information you provide in connection with
            Workkami sweepstakes, contests, or research studies in which you
            choose to participate; beta testing; and requests for customer
            support and technical assistance (collectively, “Other
            Information”). Service Usage Information. As you use the Service, we
            collect a variety of information about how you use and interact with
            the Service (“Service Usage Information”). Such information
            includes: Device information – when you access the Service using a
            mobile device, we collect certain device information, including the
            type of device you are using, its operating system, and mobile
            network information, which may include your mobile phone number. We
            may also collect your MAC address and other unique device
            identifiers. Log files – when you use the Service, our servers
            automatically record certain information in server log files. These
            log files may include information such as your web request, IP
            address, browser type and settings, referring / exit pages and URLs,
            number of clicks, date and time stamp information, language
            preferences, data from cookies and similar technologies, and other
            such information. Location information – we collect and process
            general information about the location of the device from which you
            are accessing the Service (e.g., approximate geographic location
            inferred from an IP address). Workspace Use Metadata – when you
            interact with the Service, metadata is generated that provides
            high-level (non-content) information about the way you work in your
            Workspace. For example, we may log the number of Workspaces you work
            in; the number of tasks to which you are assigned; the features and
            embedded Service content you interact with; the types of files you
            share; and what, if any, third party services and integrations you
            use. Information Collected from Third Party Integrations. If you
            choose to use or connect to third-party integrations (e.g.,
            OneDrive, Unito, Wufoo, Slack) through the Service, such third
            parties may allow us to have access to and store additional
            information about your interaction with those services as it relates
            to your use of the Service. Moreover, if you initiate these
            connections, you also understand that we will share information
            about you that is required to enable your use of the third-party
            integration through the Service. If you do not wish to have this
            information shared, do not initiate these connections. By enabling
            these connections, you authorize us to connect and access the
            information provided through these connections, and you understand
            that the privacy policies of these third parties govern such
            connections. Information Collected from Other Third Parties.
            Workkami may receive additional information about you, such as
            demographic information, from affiliates under common ownership and
            control, and from third parties, such as business partners,
            marketers, researchers, analysts, and other parties that we may use
            to supplement the information that we collect directly from you.
          </p>
          <div class="subtitle">C. Use of Free User Information</div>
          <p dir="ltr">
            Workkami may use the information collected from Free Users to: -
            Maintain, provide, and improve the Service - Respond to your
            requests for information - Prevent or address technical or security
            issues and resolve support requests - Investigate in good faith
            alleged violations of our User Terms of Service - Comply with a
            valid legal subpoena, request, or other lawful process that meets
            the requirements of our Law Enforcement Guidelines - Help us better
            understand user interests and needs, and customize the Service for
            our users - Engage in analysis, research, and reports regarding use
            of the Service - Protect the Service and our users - Communicate
            with you via email and through the Service about important notices
            and updates regarding the Service, such as to inform you about
            changes in the Service, our service offerings, and important
            services-related notices, such as about security and fraud. Because
            these communications are an important part of the Service, you may
            not opt out of them - In accordance with applicable legal
            obligations, communicate with you about promotions, offers, and news
            about Workkami. You have the ability to unsubscribe from such
            promotional communications - Provide cross-device management of your
            account. For example, we may locate or try to locate the same unique
            users across multiple browsers or devices (such as smartphones or
            tablets), or work with service providers that do this, in order to
            save your preferences across devices and analyze usage of the
            Service. If you wish to opt out of the ability of one our service
            providers, Google Analytics, to locate you across devices in this
            way, you may install the Google Analytics Opt-out Browser Add-on by
            clicking here
          </p>
          <div class="subtitle">D. Legal Bases for Use of Your Information</div>
          <p dir="ltr">
            If you are located in the EU, please note that the legal bases under
            the EU General Data Protection Regulation (“GDPR”) for using the
            information we collect through your use of the Service as a Free
            User are as follows: Where use of your information is necessary to
            perform our obligations under a contract with you (for example, to
            comply with the User Terms of Service which you accept by using the
            Service) Where use of your information is necessary for our
            legitimate interests or the legitimate interests of others (for
            example, to provide security for our Service; operate our Service;
            prevent fraud, analyze use of and improve our Service, and for
            similar purposes) Where use of your information is necessary to
            comply with a legal obligation Where we have your consent to process
            data in a certain way.
          </p>
          <div class="subtitle">E. Sharing of Free User Information</div>
          <p dir="ltr">
            We share the information we collect through the Service about Free
            Users with the following: Affiliates and Subsidiaries. We may share
            the information we collect within the Workkami family of companies.
            Service Providers. We may provide access to or share your
            information with select third parties that use the information only
            to perform services on our behalf. These third parties provide a
            variety of services to us, including without limitation sales,
            marketing, provision of content and features, advertising,
            analytics, research, data storage, security, fraud prevention, and
            other services. Business Transfers. If the ownership of all or
            substantially all of our business changes, we may transfer your
            information to the new owner so that the Websites can continue to
            operate. In such case, your information would remain subject to the
            promises and commitments contained in this Policy until such time as
            this Policy is updated or amended by the acquiring party upon notice
            to you. If such transfer is subject to additional mandatory
            restrictions under applicable laws, Workkami will comply with such
            restrictions.
          </p>
          <p dir="ltr">
            Consent. We may also disclose your information to third parties with
            your consent to do so. Consistent with your settings within the
            Service. Please note that the Workspace Content you submit through
            the Service may be viewable by other users in your Workspace,
            depending on the specific settings you have selected.
          </p>
          <div class="subtitle">F. Aggregate De-Identified Data</div>
          <p dir="ltr">
            We may aggregate and/or de-identify information collected through
            the Service so that such information can no longer be linked to you
            or your device (“Aggregate/De-Identified Information”). We may use
            Aggregate/De-Identified Information for any purpose, including
            without limitation for research and marketing purposes, and may also
            share such data with any third parties, including advertisers,
            promotional partners, sponsors, event promoters, and/or others.
          </p>
          <div class="subtitle">G. Combined Information</div>
          <p dir="ltr">
            For the purposes discussed in this Policy, we may combine the
            information that we collect through the Service with information
            that we receive from other sources, both online and offline, and use
            such combined information in accordance with this Policy.
          </p>
          <div class="subtitle">H. Data Retention</div>
          <p dir="ltr">
            We will retain your information for the period necessary to fulfill
            the purposes outlined in this Policy unless a longer retention
            period is required or permitted by law.
          </p>
          <div class="subtitle">I. Data Subject Rights</div>
          <p dir="ltr">
            Local legal requirements (such as those in the EU) may afford you
            additional rights. If you would like further information in relation
            to your legal rights under applicable law or would like to exercise
            any of them, please contact us using the information in the
            “Workkami Contact Info” section below at any time. Your local laws
            (such as those in the EU) may permit you to request that we: provide
            access to and/or a copy of certain information we hold about you
            prevent the processing of your information for direct-marketing
            purposes (including any direct marketing processing based on
            profiling) update information which is out of date or incorrect
            delete certain information which we are holding about you restrict
            the way that we process and disclose certain of your information
            transfer your information to a third party provider of services
            revoke your consent for the processing of your information We will
            consider all requests and provide our response within the time
            period stated by applicable law. Please note, however, that certain
            information may be exempt from such requests in some circumstances,
            which may include if we need to keep processing your information for
            our legitimate interests or to comply with a legal obligation. We
            may request you provide us with information necessary to confirm
            your identity before responding to your request.
          </p>
        </div>
        <div v-else-if="currentItem == 3">
          <div class="title">Privacy Terms for Site Visitors</div>
          <div class="subtitle">A. Overview</div>
          <p dir="ltr">
            Section IV of this Policy applies only to Site Visitors. If you
            visit the Websites, regardless of whether you are also a user of the
            Service, the following rules apply to you. To eliminate any
            confusion, please note that the terms in this section apply only to
            use of Workkami’s Websites, not to use of the Service. If you are a
            Site Visitor located in the European Union, Workkami is the data
            controller with respect to the processing of your personal data
            pursuant to the EU General Data Protection Regulation (“GDPR”).
          </p>
          <div class="subtitle">
            B. Collection and Use of Site Visitor Information
          </div>
          <p dir="ltr">Information Collected from Site Visitors</p>
          <p dir="ltr">
            When you use the Websites, we collect the following information
            about you:
          </p>
          <p dir="ltr">
            Contact Information – if you submit a request for information or a
            question through the Websites, you may be asked to provide us with
            basic information including your name, email address, phone number,
            and postal address. We will also keep records of the communication,
            the question/request you raised, and how it was resolved. If you
            choose to participate in an Workkami sweepstakes, contest, or
            research study offered through the Websites, we will also collect
            basic contact information from you in connection with such activity.
          </p>
          <p dir="ltr">
            Websites Usage Information – as you browse the Websites, we and our
            service providers (which are third party companies that work on our
            behalf to provide and enhance the Websites) use a variety of
            technologies, including cookies and similar tools, to assist in
            collecting information about how you use the Websites. For example,
            our servers automatically record certain information in server logs.
            These server logs may include information such as your web request,
            IP address, browser type and settings, referring / exit pages and
            URLs, number of clicks and how you interact with links on the
            Websites, domain names, landing pages, pages viewed, mobile carrier,
            mobile device identifiers and information about the device you are
            using to access the Websites, date and time stamp information and
            other such information.
          </p>
          <p dir="ltr">
            Location Information – We collect and process general information
            about the location of the device from which you are accessing the
            Service (e.g., approximate geographic location inferred from an IP
            address).
          </p>
          <p dir="ltr">Cookies and Similar Technologies</p>
          <p dir="ltr">
            To collect the Websites Usage Information discussed above, we and
            our service providers use Internet server logs, cookies, tags, SDKs,
            tracking pixels, and other similar tracking technologies. A web
            server log is a file where website activity is stored. An SDK is a
            section of code that we embed in our applications and software to
            allow third parties to collect information about how users interact
            with the Websites. A cookie is a small text file that is placed on
            your computer or mobile device when you visit a site, that enables
            us to: (i) recognize your computer and login session; (ii) store
            your preferences and settings; (iii) understand which pages of the
            Websites you have visited; (iv), enhance your user experience by
            delivering and measuring the effectiveness of content and
            advertising tailored to your interests; (v) perform analytics; and
            (vi) assist with security and administrative functions. Tracking
            pixels (sometimes referred to as web beacons or clear GIFs) are tiny
            electronic tags with a unique identifier embedded in websites,
            online ads and/or email, and that are designed to provide usage
            information like ad impressions or clicks, email open rates, measure
            popularity of the Websites and associated advertising, and to access
            user cookies. As we adopt additional technologies, we may also
            gather information through other methods. Please note that you can
            change your settings to notify you when a cookie is being set or
            updated, or to block cookies altogether. Please consult the “Help”
            section of your browser for more information (e.g., Internet
            Explorer; Google Chrome; Mozilla Firefox; or Apple Safari).
          </p>
          <p dir="ltr">
            Use of Information Collected from Site VisitorsWe use the
            information collected from Site Visitors for a variety of purposes
            including to:
          </p>
          <p dir="ltr">
            Maintain, provide, and improve the Websites and the Service
          </p>
          <p dir="ltr">Respond to your requests for information</p>
          <p dir="ltr">
            In accordance with applicable legal obligations, communicate with
            you about promotions, offers, and news about Workkami. You have the
            ability to unsubscribe from such promotional communications
          </p>
          <p dir="ltr">Prevent or address technical or security issues</p>
          <p dir="ltr">
            Investigate in good faith alleged violations of our User Terms of
            Service
          </p>
          <p dir="ltr">
            Help us better understand Site Visitor interests and needs, and
            customize the advertising and content you see on the Websites
          </p>
          <p dir="ltr">
            Engage in analysis and research regarding use of the Websites and
            the Service
          </p>
          <div class="subtitle">C. Legal Bases</div>
          <p dir="ltr">
            If you are located in the EU, please note that the legal bases under
            the GDPR for using the information we collect through your use of
            the Websites as a Site Visitor are as follows:
          </p>
          <p dir="ltr">
            Where use of your information is necessary to perform our
            obligations under a contract with you (for example, to comply with
            the User Terms of Service which you accept by browsing the Websites)
          </p>
          <p dir="ltr">
            Where use of your information is necessary for our legitimate
            interests or the legitimate interests of others (for example, to
            provide security for our Websites; operate our Websites; prevent
            fraud, analyze use of and improve our Websites, and for similar
            purposes)
          </p>
          <p dir="ltr">
            Where use of your information is necessary to comply with a legal
            obligation
          </p>
          <p dir="ltr">
            Where we have your consent to process data in a certain way
          </p>
          <div class="subtitle">D. Aggregate/De-Identified Data</div>
          <p dir="ltr">
            We may aggregate and/or de-identify information collected through
            the Websites so that such information can no longer be linked to you
            or your device (“Aggregate/De-Identified Information”). We may use
            Aggregate/De-Identified Information for any purpose, including
            without limitation for research and marketing purposes, and may also
            share such data with any third parties, including advertisers,
            promotional partners, sponsors, event promoters, and/or others.
          </p>
          <div class="subtitle">E. Combined Information</div>
          <p dir="ltr">
            You agree that, for the purposes discussed in this Policy, we may
            combine the information that we collect through the Websites with
            information that we receive from other sources, both online and
            offline, and use such combined information in accordance with this
            Policy. If, however, the collection of any information about you is
            governed by a Customer Agreement, information will only be combined
            and used in accordance with such Customer Agreement and the sections
            of this Policy applicable to Subscribers.
          </p>
          <div class="subtitle">F. Website Analytics and Advertising</div>
          <p dir="ltr">
            Online AdvertisingThe Websites may integrate third-party advertising
            technologies that allow for the delivery of relevant content and
            advertising on the Websites, as well as on other websites you visit.
            The ads may be based on various factors such as the content of the
            page you are visiting, information you enter such as your age and
            gender, your searches, demographic data, and other information we
            collect from you. These ads may be based on your current activity or
            your activity over time and across other websites and online
            services and may be tailored to your interests.Third parties, whose
            products or services are accessible or advertised via the Websites,
            may also place cookies or other tracking technologies on your
            computer, mobile phone, or other device to collect information about
            you as discussed above. We also allow other third parties (e.g., ad
            networks and ad servers such as Google Analytics, DoubleClick and
            others) to serve tailored ads to you on our Websites and other
            websites and to access their own cookies or other tracking
            technologies on your computer, mobile phone, or other device you use
            to access the Websites. We sometimes provide Site Visitor
            information (such as email addresses) to service providers, who may
            “match” this information in de-identified form to cookies (or mobile
            ad identifiers) and other proprietary IDs, in order to provide you
            with more relevant ads when you visit other websites.We neither have
            access to, nor does this Policy govern, the use of cookies or other
            tracking technologies that may be placed on your device you use to
            access the Websites by such non-affiliated third parties. If you are
            interested in more information about tailored browser advertising
            and how you can generally control cookies from being put on your
            computer to deliver tailored advertising, you may visit the Network
            Advertising Initiative’s Consumer Opt-Out link, the Digital
            Advertising Alliance’s Consumer Opt-Out link, or Your Online Choices
            to opt-out of receiving tailored advertising from companies that
            participate in those programs. To opt out of Google Analytics for
            display advertising or customize Google display network ads, visit
            the Google Ads Settings page. We do not control these opt-out links
            or whether any particular company chooses to participate in these
            opt-out programs. We are not responsible for any choices you make
            using these mechanisms or the continued availability or accuracy of
            these mechanisms.Please note that if you exercise the opt-out
            choices above, you will still see advertising when you use the
            Websites, but it will not be tailored to you based on your online
            behavior over time.
          </p>
          <p dir="ltr">
            Notice Concerning Do Not Track Do Not Track (“DNT”) is a privacy
            preference that users can set in certain web browsers. We are
            committed to providing you with meaningful choices about the
            information collected on our Websites for third party purposes, and
            that is why we provide the variety of opt-out mechanisms listed
            above. However, we do not currently recognize or respond to
            browser-initiated DNT signals. To learn more about Do Not Track, you
            can do so here.
          </p>
          <div class="subtitle">G. Sharing of Site Visitor Information</div>
          <p dir="ltr">
            We share the information we collect through the Websites with the
            following:
          </p>
          <p dir="ltr">
            Affiliates and Subsidiaries. We may share the information we collect
            within the Workkami family of companies.
          </p>
          <p dir="ltr">
            Service Providers. We may provide access to or share your
            information with select third parties that use the information only
            to perform services on our behalf. These third parties provide a
            variety of services to us, including without limitation sales,
            marketing, provision of content and features, advertising,
            analytics, research, data storage, security, fraud prevention, and
            other services.
          </p>
          <p dir="ltr">
            Business Transfers. If the ownership of all or substantially all of
            our business changes, we may transfer your information to the new
            owner so that the Websites can continue to operate. In such case,
            your information would remain subject to the promises and
            commitments contained in this Policy until such time as this Policy
            is updated or amended by the acquiring party upon notice to you. If
            such transfer is subject to additional mandatory restrictions under
            applicable laws, Workkami will comply with such restrictions.
          </p>
          <p dir="ltr">
            Public Forums. The Websites makes it possible for you to upload and
            share comments or feedback publicly with other users, such as on the
            Workkami community forum. Any information that you submit through
            such public features is not confidential, and Workkami may use it
            for any purpose (including in testimonials or other Workkami
            marketing materials). Any information you post openly in these ways
            will be available to the public at large and potentially accessible
            through third-party search engines. Such information can be read,
            collected and/or used by other users, and it could be used to send
            you unsolicited messages. Accordingly, please take care when using
            these features of the Websites.
          </p>
          <p dir="ltr">
            Consent. We may also disclose your information to third parties with
            your consent to do so.
          </p>
          <div class="subtitle">H. Retention of Your Information</div>
          <p dir="ltr">
            We will retain your information for the period necessary to fulfill
            the purposes outlined in this Policy unless a longer retention
            period is required or permitted by law.
          </p>
          <div class="subtitle">I. Data Subject Rights</div>
          <p dir="ltr">
            Local legal requirements (such as those in the EU) may afford you
            additional rights. If you would like further information in relation
            to your legal rights under applicable law or would like to exercise
            any of them, please contact us using the information in the
            “Workkami Contact Info” section below at any time. Your local laws
            (such as those in the EU) may permit you to request that we:
          </p>
          <p dir="ltr">
            provide access to and/or a copy of certain information we hold about
            you
          </p>
          <p dir="ltr">
            prevent the processing of your information for direct-marketing
            purposes (including any direct marketing processing based on
            profiling)
          </p>
          <p dir="ltr">update information which is out of date or incorrect</p>
          <p dir="ltr">
            delete certain information which we are holding about you
          </p>
          <p dir="ltr">
            restrict the way that we process and disclose certain of your
            information
          </p>
          <p dir="ltr">
            transfer your information to a third party provider of services
          </p>
          <p dir="ltr">
            revoke your consent for the processing of your information
          </p>
          <p dir="ltr">
            We will consider all requests and provide our response within the
            time period stated by applicable law. Please note, however, that
            certain information may be exempt from such requests in some
            circumstances, which may include if we need to keep processing your
            information for our legitimate interests or to comply with a legal
            obligation. We may request you provide us with information necessary
            to confirm your identity before responding to your request.
          </p>
          <div class="subtitle">J. Third Party Links And Services</div>
          <p dir="ltr">
            The Websites may contain links to third-party websites and
            functionalities. If you choose to use these third party services,
            you may disclose your information not just to those third-parties,
            but also to their users and the public more generally depending on
            how their services function. Because these third-party websites and
            services are not operated by Workkami, Workkami is not responsible
            for the content or practices of those websites or services. The
            collection, use, and disclosure of your information will be subject
            to the privacy policies of the third party websites or services, and
            not this Policy. We urge you to read the privacy and security
            policies of these third-parties.
          </p>
        </div>
        <div v-else-if="currentItem == 4">
          <div class="title">Additional Privacy Terms for All Users</div>
          <div class="subtitle">A. International Users</div>
          <p dir="ltr">
            Workkami complies with the EU-US Privacy Shield Framework and the
            Swiss-US Privacy Shield Framework as set forth by the U.S.
            Department of Commerce regarding the collection, use, and retention
            of personal data from the European Union and Switzerland to the
            United States, respectively. Workkami has certified to the
            Department of Commerce that it adheres to the Privacy Shield
            Principles of Notice, Choice, Accountability for Onward Transfer,
            Security, Data Integrity and Purpose Limitation, Access and
            Recourse, Enforcement and Liability. If there is any conflict
            between the policies in this Policy and the Privacy Shield
            Principles, the Privacy Shield Principles shall govern. To learn
            more about the Privacy Shield program, and to view our certification
            page, please visit https://www.privacyshield.gov/. In compliance
            with the EU-US Privacy Shield and Swiss-US Privacy Shield
            Principles, Workkami commits to resolve complaints about your
            privacy and our collection or use of your personal data. European
            Union or Swiss citizens with inquiries or complaints regarding this
            privacy policy should first contact Workkami at:
            privacy@workkami.com. Workkami has further committed to refer
            unresolved privacy complaints under the EU-US and Swiss-US Privacy
            Shield Principles to an independent dispute resolution mechanism,
            the BBB EU Privacy Shield, operated by the Council of Better
            Business Bureaus. If you do not receive timely acknowledgment of
            your complaint, or if your complaint is not satisfactorily addressed
            by Workkami, please visit the BBB EU Privacy Shield web site at
            https://www.bbb.org/EU-privacy-shield/for-eu-consumers/ for more
            information and to file a complaint. Please note that if your
            complaint is not resolved through these channels, under limited
            circumstances, a binding arbitration option may be available before
            a Privacy Shield Panel. Workkami is subject to the investigatory and
            enforcement powers of the Federal Trade Commission (FTC) with
            respect to its compliance with the provisions of the EU-US and
            Swiss-US Privacy Shield.
          </p>
          <p dir="ltr">
            We may transfer information that we collect about you to third party
            processors across borders and from your country or jurisdiction to
            other countries or jurisdictions around the world. If you are
            located in the European Union or other regions with laws governing
            data collection and use that may differ from U.S. law, please note
            that you are transferring information to a country and jurisdiction
            that does not have the same data protection laws as your
            jurisdiction. Workkami will take reasonable and appropriate steps
            necessary to ensure that any third party who is acting as a “data
            processor” under EU and Swiss terminology is processing the personal
            data we entrust to them in a manner that is consistent with the
            EU-US and Swiss-US Privacy Shield Principles. Workkami is
            potentially liable in cases of onward transfer to third parties of
            data of EU and Swiss individuals received pursuant to the EU-US and
            Swiss-US Privacy Shield, respectively.
          </p>
          <div class="subtitle">B. Changes To Our Privacy Policy</div>
          <p dir="ltr">
            We reserve the right to amend this Policy at any time to reflect
            changes in the law, our data collection and use practices, the
            features of our Service or Websites, or advances in technology. We
            will make the revised Policy accessible through the Service and
            Websites, so you should review the Policy periodically. If we make a
            material change to the Policy, we will comply with applicable legal
            requirements regarding providing you with notice and/or consent.
          </p>
          <div class="subtitle">C. How We Protect Your Information</div>
          <p dir="ltr">
            Workkami takes technical and organizational measures to protect your
            information against accidental or unlawful destruction or accidental
            loss, alteration, unauthorized disclosure or access. However, no
            method of transmission over the Internet, and no means of electronic
            or physical storage, is absolutely secure, and thus we cannot ensure
            or warrant the security of that information.
          </p>
          <div class="subtitle">D. How We Use Your Information.</div>
          <p dir="ltr">
            In general, we use the information we collect about you to provide
            and operate the Services, respond to your requests, and to provide
            customer service and technical support. We may also use your
            information: <br />
          </p>
          <div style="color: #484848">
            <li>
              To tailor the information that we may send or display to you,
              offer location customization, send you push notifications, alerts
              and notices, and to otherwise personalize your experiences while
              using our Services.
            </li>
            <li>
              To better understand how users access and use our Site and
              Services, both on an aggregated and individualized basis, in order
              to improve our Site and Services and respond to user desires and
              preferences, and for other research and analytical purposes.
            </li>
            <li>
              You have the right to ask Us to delete or remove Personal Data
              when there is no good reason for Us to continue processing it.
            </li>
          </div>

          <div class="subtitle">E. Marketing Practices and Choices</div>
          <p dir="ltr">
            If you receive email from us, we may use certain analytics tools,
            such as clear GIFs, to capture data such as when you open our
            message or click on any links or banners our email contains. This
            data allows us to gauge the effectiveness of our communications and
            marketing campaigns. You may instruct us not to use your contact
            information to contact you by email, postal mail, or phone regarding
            products, services, promotions and special events that might appeal
            to your interests by contacting us at the “Workkami Contact Info”
            section below. In commercial email messages, you can also opt out by
            following the instructions located at the bottom of such emails.
            Please note that, regardless of your request, we may still use and
            share certain information as permitted by this Policy or as required
            by applicable law. For example, you may not opt out of certain
            operational or service-related emails, such as those reflecting our
            relationship or transactions with you.
          </p>
          <div class="subtitle">F. California Privacy Rights</div>
          <p dir="ltr">
            California law gives residents of California the right under certain
            circumstances to request information from us regarding the manner in
            which we share certain categories of personal information (as
            defined by applicable California law) with third parties for their
            direct marketing purposes. However, Workkami does not share your
            personal information with third parties for their own direct
            marketing purposes.
          </p>
        </div>
        <div v-else-if="currentItem == 5">
          <div class="title">Workkami Contact Info</div>
          <p dir="ltr">
            Workkami is located at No.1 Charoenrat 19 Soi. , Charoenrat Road ,
            Klongsan , Klongsan , Bangkok 10600. If you wish to contact us or
            have any questions about or complaints in relation to this Policy,
            please contact us at privacy@workkami.com.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentItem: 0,
      sidebarItems: [
        'What Type of User am I and What Privacy Terms are Applicable to Me?',
        'Privacy Terms for Subscribers',
        'Privacy Terms for Free Users',
        'Privacy Terms for Site Visitors',
        'Additional Privacy Terms for All Users',
        'Workkami Contact Info'
      ]
    };
  },
  computed: {
    from() {
      return this.$route.query.from;
    }
  }
};
</script>

<style lang="scss" scoped>
.header-terms {
  background: linear-gradient(82.13deg, #0956bd 0%, #168ae2 100%);
  min-height: 199px;
}
.title-terms {
  font-weight: 500;
  font-size: 42px;
  line-height: 50px;
  margin-top: 2.5rem;
  padding-left: 5rem;
}
.signup-terms {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
}
.detail-terms {
  padding-top: 54px;
}
.sidebar {
  padding-left: 102px;
}
.sidebar-item {
  padding-right: 50px;
  color: #939393;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 20px;
}
.sidebar-btn {
  &:focus {
    outline: none;
  }
}
.sidebar-active {
  padding-left: 8px;
  border-left: 2px solid #168ae2;
  color: #333;
  outline: none;
}
.title {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 46px;
  color: #333;
  padding-bottom: 13px;
}
.subtitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #333;
  margin-top: 15px;
  margin-bottom: 10px;
}
p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #484848;
}

@media only screen and (min-width: 0px) and (max-width: 640px) {
  .header-terms {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .title-terms {
    font-weight: 500;
    font-size: 30px;
    line-height: 30px;
    padding: 0px;
    text-align: center;
    align-content: center;
    margin-top: 0px;
  }
  .signup {
    visibility: hidden;
    height: 0px;
    padding: 0px;
    margin: 0px;
    width: 0px;
  }
  .detail-terms {
    flex-direction: column;
  }
  .sidebar {
    width: 100%;
    padding-left: 15px;
  }
  .sidebar-details {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>
